<template>
    <div class="main-wrapper">
     
        <div class="title">
            <div class="titleData">
                <span style="font-size: 14px;font-weight: bold;">{{price.goods_name}}商品</span>
                <el-button type="primary" style="float: right;" @click="add">添加问题</el-button>
            </div>           
        </div>
        <el-scrollbar>
             <el-table :data="answersListTable" class="customTable" style="width: 100%; flex: 1">
                  <el-table-column   width="10px"></el-table-column>
                 <el-table-column prop="name" label="提问问题" width="390px">
                         <template slot-scope="scope">
                             <el-input style="width: 80%;" v-model="scope.row.question_name" maxlength=200 placeholder="请输入问题2-200个字"></el-input>                          
                        </template>
                 </el-table-column>
                  <el-table-column prop="name" label="正确关键词" width="250px">
                         <template slot-scope="scope">
                             <el-input style="width: 80%;" v-model="scope.row.correct_keyword" maxlength=150 placeholder="2-150个字"></el-input>                          
                        </template>
                 </el-table-column>
                 <el-table-column prop="name" label="错误关键词" width="250px">
                         <template slot-scope="scope">
                             <el-input style="width: 80%;" v-model="scope.row.wrong_keyword" maxlength=150 placeholder="2-150个字"></el-input>                          
                        </template>
                 </el-table-column>
                 <el-table-column  label="流失率" width="150px">
                        <template slot-scope="scope">
                             <el-input style="width: 70%;" maxlength=4 v-model="scope.row.lost_rate"  @blur="lostInput(scope.row)" placeholder="0.01-10"></el-input>  
                               <span style="padding-left: 10px;">%</span>                        
                        </template>
                  </el-table-column>
                   <el-table-column  label="转化率" width="150px">
                        <template slot-scope="scope">
                             <el-input style="width: 70%;" maxlength=4 v-model="scope.row.change_rate"  @blur="changeInput(scope.row)" placeholder="0.01-10"></el-input>  
                               <span style="padding-left: 10px;">%</span>                        
                        </template>
                  </el-table-column>
                   <el-table-column  label="点击率" width="150px">
                        <template slot-scope="scope">
                             <el-input style="width: 70%;" maxlength=4 v-model="scope.row.click_rate"  @blur="clickInput(scope.row)" placeholder="0.01-10"></el-input>  
                               <span style="padding-left: 10px;">%</span>                        
                        </template>
                  </el-table-column>
                   <el-table-column  label="出现次数" width="150px">
                        <template slot-scope="scope">
                             <el-input style="width: 70%;"  v-model="scope.row.appear_count"   ></el-input>  
                               <span style="padding-left: 10px;">次</span>                        
                        </template>
                  </el-table-column>
                   <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <el-link type="danger" :underline="false" @click="delAnswersList(scope.$index, scope.row)">删除</el-link>
                        </template>
                    </el-table-column>
                     <template slot="empty">
                        <div style="display: flex;flex-direction: column;align-items: center;justify-content: center;">
                          <img src="/static/img/no_data.92ce4507.png" style="margin-bottom: 10px;width: 400px;">
                          <span>暂无数据</span>
                        </div>
                    </template>
             </el-table>
        </el-scrollbar>
         <div class="footer-button">
            <el-button type="primary" @click="backTo">上一步</el-button>
            <el-button type="primary" @click="next">下一步</el-button>
         </div>
    </div>
</template>
<script>

import { validateGoodsQuestion } from "@/utils/apis.js";
export default {
    data(){
        return{
            issue_id:'',
            answersListTable:[],
            price:""
        }
    },
    created(){
        this.price = JSON.parse(localStorage.getItem("message"))
        this.$bus.$on("title", () => {        
            this.price = JSON.parse(localStorage.getItem("message"))
        })
        this.editEstablish()
    },
    methods:{
        changeInput(row){
             let two = /^[-]?([1-9]{1}[0-9]{0,}(\.[0-9]{0,2})?|0(\.[0-9]{0,2})?|\.[0-9]{1,2})$/g;
            if(!two.test(row.change_rate)){
                this.$message.error("转化率最多保留两位小数");
            }else if (isNaN(row.change_rate) || row.change_rate>10){
                 this.$message.error("转化率范围在0.01-10之间");
                 row.change_rate = 10
            }else if (isNaN(row.change_rate) || row.change_rate==0){
                this.$message.error("转化率必须大于0");
            }
        },
         lostInput(row){

             let two = /^[-]?([1-9]{1}[0-9]{0,}(\.[0-9]{0,2})?|0(\.[0-9]{0,2})?|\.[0-9]{1,2})$/g;
            if(!two.test(row.lost_rate)){
                this.$message.error("流失率率最多保留两位小数");
            }else if (isNaN(row.lost_rate) || row.lost_rate>10){
                 this.$message.error("流失率率范围在0.01-10之间");
                 row.lost_rate = 10
            }else if (isNaN(row.lost_rate) || row.lost_rate==0){
                this.$message.error("流失率率必须大于0");
            }
        },
         clickInput(row){
              let two = /^[-]?([1-9]{1}[0-9]{0,}(\.[0-9]{0,2})?|0(\.[0-9]{0,2})?|\.[0-9]{1,2})$/g;
            if(!two.test(row.click_rate)){
                this.$message.error("点击率最多保留两位小数");
            }else if (isNaN(row.click_rate) || row.click_rate>10){
                 this.$message.error("点击率范围在0.01-10之间");
                 row.click_rate = 10
            }else if (isNaN(row.click_rate) || row.click_rate==0){
                this.$message.error("点击率必须大于0");
            }

        },
        

        //编辑
        editEstablish(){
            if (this.$route.query.id) {
                let eiitList = JSON.parse(localStorage.getItem("commodityeditData"))
                this.answersListTable = eiitList.goods_question
            }
        },

        //添加
        add(){
            this.answersListTable.push({
                // id:this.issue_id++,
                correct_keyword:'',
                question_name:'',
                wrong_keyword:'',
                lost_rate:'',
                change_rate:'',
                click_rate:'',
                appear_count:'',
                answer_time:1,
                issuetime:void 0,
                issuetime1:void 0,
       
            })
        },
        //删除
        delAnswersList(index){
            this.answersListTable.splice(index, 1);
            this.$message({          
                type: "success",
                message: "删除成功",
            });
        },
        // 返回上一步
        backTo() {
            this.$router.push({
                path: "/liveStreaming/newComManagementCoupon", query: { id: this.$route.query.id }   
            });
        },
          //下一步
        next(){
             let params = {
              goods_question: this.answersListTable,
            };
            validateGoodsQuestion(params).then((res) => {
              if (res.code == 200) {
                localStorage.setItem("answers", JSON.stringify(this.answersListTable));
                this.$router.push({
                    path: "/liveStreaming/newComManagementContent",query: { 
                        id: this.$route.query.id,
                        issueList:this.answersListTable
                    }
                });
                this.$bus.$emit("answersList");
              }
            }).catch((err) => {
              console.error("err", err);
            });
           
        },
    }
}
</script>


<style lang="scss" scoped>
     .main-wrapper {
        height: 80%;
        padding: 0 30px 0 10px;    
        .title{
            text-align: center;
            padding-bottom: 20px;
            .titleData{
                line-height: 40px;
                font-size: 14px;
            }
        }   
        .el-scrollbar {
            height: 100%;
            ::v-deep .el-scrollbar__wrap {
                height: 100%;
                overflow-x: hidden;    
                 .el-scrollbar__view {
                    height: 100%;
                }
            }
        }
         .footer-button{
            text-align: center;
            padding-top: 15px;
        } 
     }
</style>